import React from "react";
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import MainBanner from '../components/MainBanner';
import PrimarySection from '../components/PrimarySection';
import HomeSectionTech from '../components/HomeSectionTech';
import HomeSectionAbout from '../components/HomeSectionAbout';
import HomeSectionTimeline from '../components/HomeSectionTimeline';


const workTimelineData = [
  {
      time: '03/2018 - súčasnosť',
      title: 'JS, PHP developer - Aliter technologies',
      text: 'Vývoj prototypov a aplikácií v React (návrh UI, použitých technológií a rozhraní s BE). Participácia pri návrhu architekúry služieb. Spolupráca pri vývoji, rozširovanie a udržiavanie aplikácie v React + node.js (fullstack). Správa a vývoj existujúcich aplikácií v Yii2 (PHP). Správa a rozširovanie Wordpress webov. Vývoj eshopu - Woocommerce (PHP). Spolupráca pri návrhu UI a riešení UX. (React, node.jS, Yii2, CSS, SASS, LESS, Docker, WordPress, WooCommerce, jQuery, REST, GRAPHQL)'
  },
  {
      time: '08/2016 - 03/2018',
      title: 'PHP, JS developer - freelancer',
      text: 'Tvorba administračných systémov (PHP, CakePHP3) - systém na online vzdelávanie (platby, testy, certifikácia), rezervačný systém s faktúrami. Vývoj CMS pre jednoduché weby (PHP, CakePHP). Úprava existujúcich webov, Wordpress šablón, vytváranie webstránok z dodaného designu.  Inštalácia a prepájanie existujúcich webových riešení (PHP). Vývoj hybridnej mobilnej platobnej aplikácie vrámci festivalu (Ionic + Angular). (CakePHP3, WordPress, jQuery, LESS, SASS, Angular2, Ionic2)'
  },
  {
      time: '2014 – 06/2016',
      title: 'Developer hybridných mobilných aplikácií - freelancer (part time)',
      text: 'jQuery Mobile, Cordova, Phonegap, Intel XDK, CSS, HTML'
  },
  {
      time: '11/2012 – 06/2013',
      title: 'FE developer - Adbee digital (part time)',
      text: 'HTML, CSS, CakePHP 2, jQuery'
  }
];

const educationTimelineData = [
  {
      time: '2014 – 2016',
      title: 'MatFyz - Univerzita komenského (Mgr.)',
      text: 'Fakulta Matematiky, Fyziky a Informatiky, Bratislava Odbor: Aplikovaná informatika (Počítačová grafika)'
  },
  {
      time: '2011 – 2014',
      title: 'MatFyz - Univerzita komenského (Bc.)',
      text: 'Fakulta Matematiky, Fyziky a Informatiky, Bratislava Odbor: Aplikovaná informatika'
  },
  {
      time: '2007 – 2011',
      title: 'Gymnázium Andreja Vrábla, Levice',
  }
];

const coursesTimelineData = [
  {
      time: '18. 11. 2021',
      title: 'AWS Certified Developer - Associate',
      text: 'Oficiálna AWS certifikácia - Developer DVA-C01'
  },
  {
      time: '1. 3. 2021',
      title: 'AWS Certified Cloud Practitioner',
      text: 'Oficiálna AWS certifikácia - Practitioner CLF-C01'
  }
];

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="O mne"/>
      <MainBanner/>
      <PrimarySection>
          <HomeSectionAbout />
          <HomeSectionTech />
      </PrimarySection>

      <HomeSectionTimeline timelineData={workTimelineData} title="Pracovné skúsenosti" />
      <HomeSectionTimeline timelineData={educationTimelineData} title="Vzdelanie" />
      <HomeSectionTimeline timelineData={coursesTimelineData} title="Kurzy a certifikácie" />

    </Layout>
  )
}

export default IndexPage
