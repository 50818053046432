import React from 'react';
import * as styles from './HomeSectionAbout.module.css';

const HomeSectionAbout = () => {
    return (
        <section className={styles.sectionAbout}>
            <h3 className={styles.sectionAboutTitle}>O mne</h3>
            <p className={styles.sectionAboutText}>
                Ahojte, volám sa Braňo. V roku 2016 som dokončil štúdium odboru Aplikovaná informatika na Matfyze v Bratislave (FMFI Univerzity
                Komenského). Už popri štúdiu som pracoval ako freelancer programovaním webových a mobilných aplikácií. Po
                skončení štúdia som pokračoval v programovaní ako freelancer. Neskôr som prešiel do
                firmy Aliter Technologies, kde pracujem ako Fullstack developer.
            </p>
        </section>
    )
}

export default HomeSectionAbout;