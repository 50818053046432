import React from 'react';
import * as styles from './HomeSectionTech.module.css';
import {CardProgress, Row, Col} from './visual'

const cardsData = [
    {
        label: 'HTML / CSS',
        value: 90
    },
    {
        label: 'JS / ES6+',
        value: 80
    },
    {
        label: 'React',
        value: 80
    },
    {
        label: 'NodeJS',
        value: 75
    },
    {
        label: 'PHP',
        value: 50
    },
    {
        label: 'WordPress',
        value: 50
    }
]

const HomeSectionTech = () => {

    const renderCards = (data) => {
        return data.map(item => {
            return (
                <Col xs="6" sm="4" md="4" lg="2" key={item.label} className={styles.sectionTechCard}>
                    <CardProgress value={item.value} label={item.label} />
                </Col>
            )
        });
    }

    return (
        <section className={styles.sectionTech}>
            <h3 className={styles.sectionTechTitle}>Technológie</h3>
            <Row>
                {renderCards(cardsData)}
            </Row>

            <p className={styles.sectionTechAdditional}>
                MATERIAL-UI, GraphQL, Redux, TypeScript, Mocha + Chai, ANGULAR 2, IONIC 2, Gatsby, JAM stack, REST, Next.js, Leaflet, CakePHP 3, Yii2, WooCommerce, CodeIgniter 2, DOCKER, GIT, SQL, SASS, LESS, styled components, BEM, CSS modules, Bootstrap, PWA, jQuery, jQuery mobile, Gulp, Linux <small>(používateľ)</small>, ...
            </p>
        </section>
    );
}

export default HomeSectionTech;